// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Card,
//   CardContent,
//   Grid,
//   Typography,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   CircularProgress,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Chip,
//   Paper
// } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import Chart from 'react-apexcharts';
// import AdminService from '../../module/adminService';

// // Styled Components (reuse your existing styled components)
// const StyledCard = styled(Card)(({ theme }) => ({
//   borderRadius: theme.spacing(2),
//   backgroundColor: '#fff',
//   boxShadow: '0 8px 40px rgba(118, 149, 255, 0.1)',
//   transition: 'transform 0.2s ease-in-out',
//   height: '100%',
//   '&:hover': {
//     transform: 'translateY(-4px)',
//     boxShadow: '0 12px 48px rgba(118, 149, 255, 0.15)',
//   },
// }));

// const UserActivityMonitoring = () => {
//   const [loading, setLoading] = useState(true);
//   const [dashboardData, setDashboardData] = useState(null);
//   const [inactiveUsers, setInactiveUsers] = useState([]);
//   const [activityStats, setActivityStats] = useState(null);
//   const [detailedStats, setDetailedStats] = useState(null);
//   const [selectedDays, setSelectedDays] = useState(30);

//   useEffect(() => {
//     fetchDashboardData();
//   }, [selectedDays]);

//   const fetchDashboardData = async () => {
//     setLoading(true);
//     try {
//       const [dashboard, stats, detailed] = await Promise.all([
//         AdminService.getAdminActivityDashboard(),
//         AdminService.getActivityStats(),
//         AdminService.getDetailedUserStats()
//       ]);

//       setDashboardData(dashboard);
//       setActivityStats(stats);
//       setDetailedStats(detailed);
//       setInactiveUsers(dashboard.inactiveUsers.content || []);
//     } catch (error) {
//       console.error('Error fetching dashboard data:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Activity Distribution Chart Config
//   const distributionChartOptions = {
//     chart: {
//       type: 'donut'
//     },
//     labels: ['Active', 'Inactive', 'Dormant'],
//     colors: ['#00E396', '#FEB019', '#FF4560'],
//     legend: {
//       position: 'bottom'
//     },
//     responsive: [{
//       breakpoint: 480,
//       options: {
//         chart: {
//           width: 300
//         },
//         legend: {
//           position: 'bottom'
//         }
//       }
//     }]
//   };

//   const distributionSeries = activityStats ? [
//     activityStats.activeUsers || 0,
//     activityStats.inactiveUsers || 0,
//     activityStats.dormantUsers || 0
//   ] : [];

//   // Login Activity Chart Config
//   const loginActivityOptions = {
//     chart: {
//       type: 'area',
//       height: 350,
//       toolbar: {
//         show: true
//       },
//       zoom: {
//         enabled: true
//       }
//     },
//     dataLabels: {
//       enabled: false
//     },
//     stroke: {
//       curve: 'smooth'
//     },
//     xaxis: {
//       type: 'datetime'
//     },
//     yaxis: {
//       title: {
//         text: 'Login Count'
//       }
//     },
//     tooltip: {
//       x: {
//         format: 'dd MMM yyyy'
//       }
//     }
//   };

//   const loginActivitySeries = detailedStats?.loginTrends ? [
//     {
//       name: 'Login Activity',
//       data: detailedStats.loginTrends
//     }
//   ] : [];

//   const formatDate = (dateString) => {
//     if (!dateString) return '-';
//     return new Date(dateString).toLocaleDateString();
//   };

//   if (loading) {
//     return (
//       <Box display="flex" justifyContent="center" alignItems="center" height="400px">
//         <CircularProgress />
//       </Box>
//     );
//   }

//   return (
//     <Box sx={{ mt: 3 }}>
//       <Grid container spacing={3}>
//         {/* Activity Stats Cards */}
//         <Grid item xs={12} md={3}>
//           <StyledCard>
//             <CardContent>
//               <Typography variant="h6" color="textSecondary">Active Users</Typography>
//               <Typography variant="h3">{activityStats?.activeUsers || 0}</Typography>
//             </CardContent>
//           </StyledCard>
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <StyledCard>
//             <CardContent>
//               <Typography variant="h6" color="textSecondary">Inactive Users</Typography>
//               <Typography variant="h3">{activityStats?.inactiveUsers || 0}</Typography>
//             </CardContent>
//           </StyledCard>
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <StyledCard>
//             <CardContent>
//               <Typography variant="h6" color="textSecondary">Total Logins</Typography>
//               <Typography variant="h3">{detailedStats?.totalLogins || 0}</Typography>
//             </CardContent>
//           </StyledCard>
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <StyledCard>
//             <CardContent>
//               <Typography variant="h6" color="textSecondary">Avg. Daily Logins</Typography>
//               <Typography variant="h3">
//                 {detailedStats?.averageDailyLogins?.toFixed(1) || 0}
//               </Typography>
//             </CardContent>
//           </StyledCard>
//         </Grid>

//         {/* Activity Distribution Chart */}
//         <Grid item xs={12} md={6}>
//           <StyledCard>
//             <CardContent>
//               <Typography variant="h6" gutterBottom>User Activity Distribution</Typography>
//               <Chart
//                 options={distributionChartOptions}
//                 series={distributionSeries}
//                 type="donut"
//                 height={350}
//               />
//             </CardContent>
//           </StyledCard>
//         </Grid>

//         {/* Login Activity Chart */}
//         <Grid item xs={12} md={6}>
//           <StyledCard>
//             <CardContent>
//               <Typography variant="h6" gutterBottom>Login Activity Trend</Typography>
//               <Chart
//                 options={loginActivityOptions}
//                 series={loginActivitySeries}
//                 type="area"
//                 height={350}
//               />
//             </CardContent>
//           </StyledCard>
//         </Grid>

//         {/* Inactive Users Table */}
//         <Grid item xs={12}>
//           <StyledCard>
//             <CardContent>
//               <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
//                 <Typography variant="h6">Inactive Users</Typography>
//                 <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
//                   <InputLabel>Inactive Days</InputLabel>
//                   <Select
//                     value={selectedDays}
//                     onChange={(e) => setSelectedDays(e.target.value)}
//                     label="Inactive Days"
//                   >
//                     <MenuItem value={7}>7 Days</MenuItem>
//                     <MenuItem value={30}>30 Days</MenuItem>
//                     <MenuItem value={90}>90 Days</MenuItem>
//                   </Select>
//                 </FormControl>
//               </Box>
//               <TableContainer component={Paper}>
//                 <Table>
//                   <TableHead>
//                     <TableRow>
//                       <TableCell>User</TableCell>
//                       <TableCell>Email</TableCell>
//                       <TableCell>Last Login</TableCell>
//                       <TableCell>Login Count</TableCell>
//                       <TableCell>Status</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {inactiveUsers.map((user) => (
//                       <TableRow key={user.id}>
//                         <TableCell>{user.name}</TableCell>
//                         <TableCell>{user.email}</TableCell>
//                         <TableCell>{formatDate(user.lastUpdated)}</TableCell>
//                         <TableCell>{user.loginCount}</TableCell>
//                         <TableCell>
//                           <Chip
//                             label={user.activityStatus}
//                             color={
//                               user.activityStatus === 'ACTIVE' ? 'success' :
//                               user.activityStatus === 'INACTIVE' ? 'warning' :
//                               'error'
//                             }
//                             size="small"
//                           />
//                         </TableCell>
//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </CardContent>
//           </StyledCard>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default UserActivityMonitoring;










import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Chart from 'react-apexcharts';
import { Icon } from '@iconify/react';
import AdminService from '../../module/adminService';

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: '#fff',
  boxShadow: '0 8px 40px rgba(118, 149, 255, 0.1)',
  transition: 'transform 0.2s ease-in-out',
  height: '100%',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 12px 48px rgba(118, 149, 255, 0.15)',
  },
}));

const StyledStatCard = styled(StyledCard)(({ theme }) => ({
  '.icon-wrapper': {
    backgroundColor: 'rgba(118, 149, 255, 0.1)',
    borderRadius: '50%',
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  '.trend-indicator': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    marginTop: theme.spacing(1),
  }
}));

const UserActivityMonitoring = () => {
    const [loading, setLoading] = useState(true);
    const [dashboardData, setDashboardData] = useState(null);
    const [inactiveUsers, setInactiveUsers] = useState([]);
    const [activityStats, setActivityStats] = useState(null);
    const [detailedStats, setDetailedStats] = useState(null);
    const [selectedDays, setSelectedDays] = useState(30);
  
    useEffect(() => {
      fetchDashboardData();
    }, [selectedDays]);
  
    const fetchDashboardData = async () => {
      setLoading(true);
      try {
        const [dashboard, stats, detailed] = await Promise.all([
          AdminService.getActivityDashboard(),
          AdminService.getActivityStats(),
          AdminService.getDetailedStats()
        ]);
  
        setDashboardData(dashboard);
        setActivityStats(stats);
        setDetailedStats(detailed);
        setInactiveUsers(dashboard.inactiveUsers.content || []);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    // Activity Distribution Chart Config
    const distributionChartOptions = {
      chart: {
        type: 'donut'
      },
      labels: ['Active', 'Inactive', 'Dormant'],
      colors: ['#00E396', '#FEB019', '#FF4560'],
      legend: {
        position: 'bottom'
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 300
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    };
  
    const distributionSeries = activityStats ? [
      activityStats.activeUsers || 0,
      activityStats.inactiveUsers || 0,
      activityStats.dormantUsers || 0
    ] : [];
  
    // Login Activity Chart Config
    const loginActivityOptions = {
      chart: {
        type: 'area',
        height: 350,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime'
      },
      yaxis: {
        title: {
          text: 'Login Count'
        }
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy'
        }
      }
    };
  
    const loginActivitySeries = AdminService.getDetailedStats.averageLoginCount ? [
      {
        name: 'Login Activity',
        data: detailedStats.loginTrends
      }
    ] : [];
  
    const formatDate = (dateString) => {
      if (!dateString) return '-';
      return new Date(dateString).toLocaleDateString();
    };
  
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="400px">
          <CircularProgress />
        </Box>
      );
    }
  
  const statsCards = [
    {
      title: 'Active Users',
      value: activityStats?.activeUsers || 0,
      icon: 'mdi:account-check',
      color: '#00E396',
      trend: '+5%',
      trendUp: true
    },
    {
      title: 'Inactive Users',
      value: activityStats?.inactiveUsers || 0,
      icon: 'mdi:account-clock',
      color: '#FEB019',
      trend: '+2%',
      trendUp: true
    },
    {
      title: 'Total Logins',
      value: detailedStats?.totalLogins || 0,
      icon: 'mdi:login-variant',
      color: '#008FFB',
      trend: '+12%',
      trendUp: true
    },
    {
      title: 'Avg. Daily Logins',
      value: (detailedStats?.averageDailyLogins || 0).toFixed(1),
      icon: 'mdi:chart-line',
      color: '#FF4560',
      trend: '-3%',
      trendUp: false
    }
  ];

  return (
    <Box sx={{ mt: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h5" fontWeight="600">
          <Icon icon="mdi:chart-box" width={24} height={24} style={{ verticalAlign: 'middle', marginRight: 8 }} />
          Activity Analytics
        </Typography>
        <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
          <InputLabel>Time Range</InputLabel>
          <Select
            value={selectedDays}
            onChange={(e) => setSelectedDays(e.target.value)}
            label="Time Range"
          >
            <MenuItem value={7}>Last 7 Days</MenuItem>
            <MenuItem value={30}>Last 30 Days</MenuItem>
            <MenuItem value={90}>Last 90 Days</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Stats Cards with Icons */}
      <Grid container spacing={3} mb={3}>
        {statsCards.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <StyledStatCard>
              <CardContent>
                <div className="icon-wrapper" style={{ backgroundColor: `${card.color}20` }}>
                  <Icon icon={card.icon} width={24} height={24} style={{ color: card.color }} />
                </div>
                <Typography variant="h4" fontWeight="600">
                  {card.value}
                </Typography>
                <Typography color="textSecondary" variant="subtitle2">
                  {card.title}
                </Typography>
                <div className="trend-indicator">
                  <Icon 
                    icon={card.trendUp ? 'mdi:trending-up' : 'mdi:trending-down'} 
                    width={20} 
                    height={20} 
                    style={{ color: card.trendUp ? '#00E396' : '#FF4560' }} 
                  />
                  <Typography 
                    variant="body2" 
                    color={card.trendUp ? 'success.main' : 'error.main'}
                  >
                    {card.trend}
                  </Typography>
                </div>
              </CardContent>
            </StyledStatCard>
          </Grid>
        ))}
      </Grid>

      {/* Charts Section */}
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Icon icon="mdi:chart-pie" width={24} height={24} style={{ marginRight: 8, color: '#7695FF' }} />
                <Typography variant="h6">User Distribution</Typography>
              </Box>
              <Chart
                options={distributionChartOptions}
                series={distributionSeries}
                type="donut"
                height={350}
              />
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Icon icon="mdi:chart-timeline-variant" width={24} height={24} style={{ marginRight: 8, color: '#7695FF' }} />
                <Typography variant="h6">Login Trends</Typography>
              </Box>
              <Chart
                options={loginActivityOptions}
                series={loginActivitySeries}
                type="area"
                height={350}
              />
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>

      {/* Inactive Users Table */}
      <StyledCard>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Box display="flex" alignItems="center">
              <Icon icon="mdi:account-off" width={24} height={24} style={{ marginRight: 8, color: '#7695FF' }} />
              <Typography variant="h6">Inactive Users</Typography>
            </Box>
            <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
              <InputLabel>Inactive Days</InputLabel>
              <Select
                value={selectedDays}
                onChange={(e) => setSelectedDays(e.target.value)}
                label="Inactive Days"
                startAdornment={
                  <Icon icon="mdi:calendar-clock" style={{ marginRight: 8 }} />
                }
              >
                <MenuItem value={7}>7 Days</MenuItem>
                <MenuItem value={30}>30 Days</MenuItem>
                <MenuItem value={90}>90 Days</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Icon icon="mdi:account" width={20} height={20} style={{ marginRight: 8 }} />
                      User
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Icon icon="mdi:email" width={20} height={20} style={{ marginRight: 8 }} />
                      Email
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Icon icon="mdi:clock-outline" width={20} height={20} style={{ marginRight: 8 }} />
                      Last Login
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Icon icon="mdi:counter" width={20} height={20} style={{ marginRight: 8 }} />
                      Login Count
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Icon icon="mdi:account-alert" width={20} height={20} style={{ marginRight: 8 }} />
                      Status
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inactiveUsers.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{formatDate(user.lastUpdated)}</TableCell>
                    <TableCell>{user.loginCount}</TableCell>
                    <TableCell>
                      <Chip
                        icon={
                          <Icon 
                            icon={
                              user.activityStatus === 'ACTIVE' ? 'mdi:account-check' :
                              user.activityStatus === 'INACTIVE' ? 'mdi:account-clock' :
                              'mdi:account-off'
                            } 
                            width={16} 
                            height={16} 
                          />
                        }
                        label={user.activityStatus}
                        color={
                          user.activityStatus === 'ACTIVE' ? 'success' :
                          user.activityStatus === 'INACTIVE' ? 'warning' :
                          'error'
                        }
                        size="small"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </StyledCard>
    </Box>
  );
};

export default UserActivityMonitoring;