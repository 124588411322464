import trackerApi from './trackerApi';



class AdminService {
//   static getAllUsers(page = 0, size = 20) {
//     return trackerApi({
//       method: 'GET',
//       url: '/user/admin/users',
//       params: {
//         page,
//         size
//       }
//     })
//       .then((response) => response.data)
//       .catch((error) => {
//         console.error('Error fetching users:', error);
//         throw error;
//       });
//   }


static getAdmin(userId) {
  return trackerApi({
    method: 'GET',
    url: `/user/admin/${userId}`,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error checking admin status:', error);
      throw error;
    });
}



static async getAllUsers() {
    try {
      const response = await trackerApi({
        method: 'GET',
        url: '/user/admin/users',
        params: {
          size: 1000 // Get more records at once
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;
    }
  }

  static searchUsers(email = '', name = '', page = 0, size = 20) {
    const queryParams = new URLSearchParams();
    if (email) queryParams.append('email', email);
    if (name) queryParams.append('name', name);
    queryParams.append('page', page);
    queryParams.append('size', size);
    
    return trackerApi({
      method: 'GET',
      url: `/user/admin/users/search?${queryParams.toString()}`,
    })
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error searching users:', error);
        throw error;
      });
  }

  static getUserStats() {
    return trackerApi({
      method: 'GET',
      url: '/user/admin/stats',
    })
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error fetching user stats:', error);
        throw error;
      });
  }



  static makeAdmin(userId) {
    return trackerApi({
      method: 'POST',
      url: '/user/admin/make-admin',
      data: { userId },
    })
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error making user admin:', error);
        throw error;
      });
  }

  static removeAdmin(userId) {
    return trackerApi({
      method: 'POST',
      url: '/user/admin/remove-admin',
      data: { userId },
    })
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error removing admin status:', error);
        throw error;
      });
  }

  static getAllAdmins() {
    return trackerApi({
      method: 'GET',
      url: '/user/admin/admins',
    })
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error fetching admins:', error);
        throw error;
      });
  }

  // Helper method to handle bulk operations if needed
  static async bulkUpdateAdminStatus(userIds, makeAdmin = true) {
    const promises = userIds.map((userId) =>
      makeAdmin ? this.makeAdmin(userId) : this.removeAdmin(userId)
    );

    try {
      return await Promise.all(promises);
    } catch (error) {
      console.error('Error in bulk admin update:', error);
      throw error;
    }
  }

  // Add error handling wrapper for service methods
  static async withErrorHandling(serviceCall, errorMessage) {
    try {
      return await serviceCall();
    } catch (error) {
      // Handle specific error cases
      if (error.response?.status === 403) {
        throw new Error('You do not have permission to perform this action');
      }
      if (error.response?.status === 404) {
        throw new Error('User not found');
      }
      throw new Error(errorMessage || 'An error occurred while processing your request');
    }
  }



  static async getActivityDashboard() {
    try {
      const response = await trackerApi({
        method: 'GET',
        url: '/user/admin/activity'
      });
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  static async getActivityStats() {
    try {
      const response = await trackerApi({
        method: 'GET',
        url: '/user/activity/stats'
      });
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  static async getDetailedStats(startDate, endDate, page = 0, size = 10) {
    try {
      const response = await trackerApi({
        method: 'GET',
        url: '/user/activity/detailed-stats',
        params: {
          page,
          size,
          ...(startDate && { startDate }),
          ...(endDate && { endDate })
        }
      });
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  static async getInactiveUsers(days = 30, page = 0, size = 10) {
    try {
      const response = await trackerApi({
        method: 'GET',
        url: '/user/activity/inactive',
        params: {
          days,
          page,
          size
        }
      });
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  static async getUsersByStatus(status, page = 0, size = 10) {
    try {
      const response = await trackerApi({
        method: 'GET',
        url: `/user/activity/by-status/${status}`,
        params: {
          page,
          size
        }
      });
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  static async getUserActivity(userId) {
    try {
      const response = await trackerApi({
        method: 'GET',
        url: `/user/activity/${userId}`
      });
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  static handleError(error) {
    console.error('AdminService Error:', error);
    throw new Error(error.response?.data?.message || 'An error occurred');
  }


  
}

export default AdminService;