
// import React, { useState, useEffect } from 'react';
// import { styled } from '@mui/material/styles';
// import {
//   Box,
//   Container,
//   Paper,
//   Typography,
//   Tabs,
//   Tab,
//   Alert,
//   CircularProgress,
//   Card,
//   CardContent,
//   Grid,
//   TextField,
//   Button,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   IconButton,
//   Snackbar, TablePagination,
//   Chip
// } from '@mui/material';
// import { Icon } from '@iconify/react';
// import AdminService from '../../module/adminService';

// // Styled Components
// const StyledContainer = styled(Container)(({ theme }) => ({
//   padding: theme.spacing(3),
//   [theme.breakpoints.up('sm')]: {
//     padding: theme.spacing(4),
//   },
//   [theme.breakpoints.up('md')]: {
//     padding: theme.spacing(5),
//   },
// }));

// const StyledHeader = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   justifyContent: 'space-between',
//   alignItems: 'center',
//   marginBottom: theme.spacing(4),
//   gap: theme.spacing(2),
// }));

// const StyledCard = styled(Card)(({ theme }) => ({
//   borderRadius: theme.spacing(2),
//   backgroundColor: '#fff',
//   boxShadow: '0 8px 40px rgba(118, 149, 255, 0.1)',
//   transition: 'transform 0.2s ease-in-out',
//   height: '100%',
//   '&:hover': {
//     transform: 'translateY(-4px)',
//     boxShadow: '0 12px 48px rgba(118, 149, 255, 0.15)',
//   },
// }));

// const StyledSearchBox = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   gap: theme.spacing(2),
//   marginBottom: theme.spacing(3),
//   flexDirection: 'column',
//   [theme.breakpoints.up('sm')]: {
//     flexDirection: 'row',
//   },
// }));

// const StyledTextField = styled(TextField)(({ theme }) => ({
//   '& .MuiOutlinedInput-root': {
//     borderRadius: theme.spacing(1.5),
//     backgroundColor: '#F8F9FF',
//     '&.Mui-focused': {
//       backgroundColor: '#fff',
//     },
//     '& fieldset': {
//       borderColor: 'rgba(118, 149, 255, 0.1)',
//     },
//     '&:hover fieldset': {
//       borderColor: '#7695FF',
//     },
//     '&.Mui-focused fieldset': {
//       borderColor: '#7695FF',
//     },
//   },
// }));

// const StyledButton = styled(Button)(({ theme }) => ({
//   borderRadius: theme.spacing(1.5),
//   padding: theme.spacing(1.5),
//   backgroundColor: '#7695FF',
//   color: '#fff',
//   textTransform: 'none',
//   fontSize: '1rem',
//   fontWeight: 600,
//   '&:hover': {
//     backgroundColor: '#6384FF',
//   },
// }));

// const TabPanel = ({ children, value, index, ...other }) => (
//   <div
//     role="tabpanel"
//     hidden={value !== index}
//     id={`admin-tabpanel-${index}`}
//     {...other}
//   >
//     {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
//   </div>
// );





// const AdminDashboard = () => {
//     const [stats, setStats] = useState({
//       totalUsers: 0,
//       activeUsers: 0,
//       verifiedUsers: 0,
//       subscribedUsers: 0
//     });
//     const [users, setUsers] = useState([]);
//     const [searchEmail, setSearchEmail] = useState('');
//     const [searchName, setSearchName] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [snackbar, setSnackbar] = useState({
//       open: false,
//       message: '',
//       severity: 'success'
//     });
    
//     // Pagination state
//     const [page, setPage] = useState(0);
//     const [rowsPerPage, setRowsPerPage] = useState(20);
//     const [totalElements, setTotalElements] = useState(0);
  
//     useEffect(() => {
//       fetchInitialData();
//     }, [page, rowsPerPage]);
  
//     const fetchInitialData = async () => {
//       setLoading(true);
//       try {
//         await Promise.all([fetchStats(), fetchUsers()]);
//       } catch (error) {
//         handleError(error);
//       } finally {
//         setLoading(false);
//       }
//     };
  
//     const fetchStats = async () => {
//       try {
//         const statsData = await AdminService.withErrorHandling(
//           () => AdminService.getUserStats(),
//           'Failed to fetch user statistics'
//         );
//         setStats(statsData);
//       } catch (error) {
//         handleError(error);
//       }
//     };
  
//     const fetchUsers = async () => {
//       try {
//         if (searchEmail || searchName) {
//           const userData = await AdminService.withErrorHandling(
//             () => AdminService.searchUsers(searchEmail, searchName),
//             'Failed to search users'
//           );
//           setUsers(userData.content);
//           setTotalElements(userData.totalElements);
//         } else {
//           const userData = await AdminService.withErrorHandling(
//             () => AdminService.getAllUsers(),
//             'Failed to fetch users'
//           );
//           setUsers(userData.content);
//           setTotalElements(userData.totalElements);
//         }
//       } catch (error) {
//         handleError(error);
//       }
//     };
  
//     const handleMakeAdmin = async (userId) => {
//       try {
//         await AdminService.withErrorHandling(
//           () => AdminService.makeAdmin(userId),
//           'Failed to grant admin privileges'
//         );
//         setSnackbar({
//           open: true,
//           message: 'Successfully granted admin privileges',
//           severity: 'success'
//         });
//         fetchUsers();
//       } catch (error) {
//         handleError(error);
//       }
//     };
  
//     const handleRemoveAdmin = async (userId) => {
//       try {
//         await AdminService.withErrorHandling(
//           () => AdminService.removeAdmin(userId),
//           'Failed to remove admin privileges'
//         );
//         setSnackbar({
//           open: true,
//           message: 'Successfully removed admin privileges',
//           severity: 'success'
//         });
//         fetchUsers();
//       } catch (error) {
//         handleError(error);
//       }
//     };
  
//     const handleError = (error) => {
//       setSnackbar({
//         open: true,
//         message: error.message || 'An error occurred',
//         severity: 'error'
//       });
//     };
  
//     const handleSnackbarClose = (event, reason) => {
//       if (reason === 'clickaway') return;
//       setSnackbar({ ...snackbar, open: false });
//     };
  
//     const handleSearch = () => {
//       setPage(0);
//       fetchUsers();
//     };
  
//     const handleChangePage = (event, newPage) => {
//       setPage(newPage);
//     };
  
//     const handleChangeRowsPerPage = (event) => {
//       setRowsPerPage(parseInt(event.target.value, 10));
//       setPage(0);
//     };
  
//     const formatDate = (dateString) => {
//       if (!dateString) return '-';
//       return new Date(dateString).toLocaleDateString('en-US', {
//         year: 'numeric',
//         month: 'short',
//         day: 'numeric'
//       });
//     };
  
//     const statCards = [
//       { title: 'Total Users', value: totalElements, icon: 'mdi:account-multiple' },
//       { title: 'Active Users', value: stats.activeUsers, icon: 'mdi:account-check' },
//       { title: 'Verified Users', value: stats.verifiedUsers, icon: 'mdi:check-decagram' },
//       { title: 'Subscribed Users', value: stats.subscribedUsers, icon: 'mdi:star-circle' }
//     ];
  
//     return (
//       <>
//         <StyledContainer maxWidth="xl">
//           <StyledHeader>
//             <Typography variant="h4" sx={{ fontWeight: 600 }}>
//               Admin Dashboard
//             </Typography>
//             {loading && <CircularProgress size={24} sx={{ color: '#7695FF' }} />}
//           </StyledHeader>
  
//           <Grid container spacing={3} sx={{ mb: 4 }}>
//             {statCards.map((card, index) => (
//               <Grid item xs={12} sm={6} md={3} key={index}>
//                 <StyledCard>
//                   <CardContent>
//                     <Box display="flex" alignItems="center" mb={1}>
//                       <Icon 
//                         icon={card.icon}
//                         width={24}
//                         height={24}
//                         style={{ marginRight: 8, color: '#7695FF' }}
//                       />
//                       <Typography color="textSecondary">{card.title}</Typography>
//                     </Box>
//                     <Typography variant="h4" sx={{ fontWeight: 600 }}>
//                       {card.value}
//                     </Typography>
//                   </CardContent>
//                 </StyledCard>
//               </Grid>
//             ))}
//           </Grid>
  
//           <StyledCard>
//             <CardContent>
//               <StyledSearchBox>
//                 <StyledTextField
//                   fullWidth
//                   label="Search by Email"
//                   value={searchEmail}
//                   onChange={(e) => setSearchEmail(e.target.value)}
//                   InputProps={{
//                     startAdornment: (
//                       <Icon icon="mdi:email" width={20} height={20} style={{ marginRight: 8, color: '#637381' }} />
//                     ),
//                   }}
//                 />
//                 <StyledTextField
//                   fullWidth
//                   label="Search by Name"
//                   value={searchName}
//                   onChange={(e) => setSearchName(e.target.value)}
//                   InputProps={{
//                     startAdornment: (
//                       <Icon icon="mdi:account" width={20} height={20} style={{ marginRight: 8, color: '#637381' }} />
//                     ),
//                   }}
//                 />
//                 <StyledButton
//                   onClick={handleSearch}
//                   disabled={loading}
//                 >
//                   <Icon icon="mdi:magnify" width={50} height={30} />
//                 </StyledButton>
//               </StyledSearchBox>
  
//               <TableContainer>
//                 <Table>
//                   <TableHead>
//                     <TableRow>
//                       <TableCell>Name</TableCell>
//                       <TableCell>Email</TableCell>
//                       <TableCell>Created Date</TableCell>
//                       <TableCell>Status</TableCell>
//                       <TableCell>Subscription</TableCell>
//                       <TableCell>Actions</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {users.map((user) => (
//                       <TableRow key={user.id}>
//                         <TableCell>{user.name}</TableCell>
//                         <TableCell>{user.email}</TableCell>
//                         <TableCell>{formatDate(user.creationDate)}</TableCell>
//                         <TableCell>
//                           <Box display="flex" gap={1} flexWrap="wrap">
//                             <Chip
//                               icon={<Icon icon={user.verified ? "mdi:check-circle" : "mdi:alert-circle"} />}
//                               label={user.verified ? 'Verified' : 'Unverified'}
//                               color={user.verified ? "success" : "warning"}
//                               size="small"
//                             />
//                             {user.deleted && (
//                               <Chip
//                                 icon={<Icon icon="mdi:trash-can" />}
//                                 label="Deleted"
//                                 color="error"
//                                 size="small"
//                               />
//                             )}
//                           </Box>
//                         </TableCell>
//                         <TableCell>
//                           <Chip
//                             icon={<Icon icon={user.subscribed ? "mdi:star" : "mdi:star-outline"} />}
//                             label={user.free ? 'Free' : (user.subscribed ? 'Subscribed' : 'Inactive')}
//                             color={user.subscribed ? "primary" : (user.free ? "default" : "error")}
//                             size="small"
//                           />
//                         </TableCell>
//                         <TableCell>
//                           <IconButton 
//                             size="small" 
//                             onClick={() => user.admin ? handleRemoveAdmin(user.id) : handleMakeAdmin(user.id)}
//                             title={user.admin ? "Remove admin privileges" : "Grant admin privileges"}
//                           >
//                             <Icon 
//                               icon={user.admin ? "mdi:shield-off" : "mdi:shield"} 
//                               width={20} 
//                               height={20} 
//                             />
//                           </IconButton>
//                         </TableCell>
//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>
//               </TableContainer>
              
         
//         <TablePagination
//           component="div"
//           count={totalElements}
//           page={page}
//           onPageChange={handleChangePage}
//           rowsPerPage={rowsPerPage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//           rowsPerPageOptions={[10, 20, 50]}
//           SelectProps={{
//             native: true,
//           }}
//         />
//             </CardContent>
//           </StyledCard>
//         </StyledContainer>
  
//         <Snackbar
//           open={snackbar.open}
//           autoHideDuration={6000}
//           onClose={handleSnackbarClose}
//           anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
//         >
//           <Alert
//             onClose={handleSnackbarClose}
//             severity={snackbar.severity}
//             sx={{ width: '100%' }}
//             variant="filled"
//           >
//             {snackbar.message}
//           </Alert>
//         </Snackbar>
//       </>
//     );
//   };
// export default AdminDashboard;




import React, { useState, useEffect, useRef, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Container,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Divider
} from '@mui/material';
import { Icon } from '@iconify/react';
import AdminService from '../../module/adminService';


// Styled Components
const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(5),
  },
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
  gap: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: '#fff',
  boxShadow: '0 8px 40px rgba(118, 149, 255, 0.1)',
  transition: 'transform 0.2s ease-in-out',
  
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 12px 48px rgba(118, 149, 255, 0.15)',
  },
}));

const StyledSearchBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

const StyledFilterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
  alignItems: 'center',
  flexWrap: 'wrap',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(1.5),
    backgroundColor: '#F8F9FF',
    '&.Mui-focused': {
      backgroundColor: '#fff',
    },
    '& fieldset': {
      borderColor: 'rgba(118, 149, 255, 0.1)',
    },
    '&:hover fieldset': {
      borderColor: '#7695FF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#7695FF',
    },
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 120,
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(1.5),
    backgroundColor: '#F8F9FF',
    '&.Mui-focused': {
      backgroundColor: '#fff',
    },
    '& fieldset': {
      borderColor: 'rgba(118, 149, 255, 0.1)',
    },
    '&:hover fieldset': {
      borderColor: '#7695FF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#7695FF',
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(0.8),
  backgroundColor: '#7695FF',
  color: '#fff',
  textTransform: 'none',
  fontSize: '1rem',
  fontWeight: 600,
  '&:hover': {
    backgroundColor: '#6384FF',
  },
  '&.MuiButton-outlined': {
    backgroundColor: 'transparent',
    borderColor: '#7695FF',
    color: '#7695FF',
    '&:hover': {
      backgroundColor: 'rgba(118, 149, 255, 0.1)',
    },
  },
}));

const LoadingIndicator = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2),
  width: '100%',
}));

const AdminDashboard = () => {
  // State Management
  const [stats, setStats] = useState({
    totalUsers: 0,
    activeUsers: 0,
    verifiedUsers: 0,
    subscribedUsers: 0,
    freeUsers:0,
  });
  
  const [users, setUsers] = useState([]);
  const [searchEmail, setSearchEmail] = useState('');
  const [searchName, setSearchName] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [totalElements, setTotalElements] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const rowsPerPage = 20;

  const [filters, setFilters] = useState({
    dateSort: 'none',
    subscriptionFilter: 'all',
    nameSort: 'none'
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Intersection Observer for Infinite Scroll
  const observer = useRef();
  const lastUserElementRef = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  // Effects
// Initial data fetch
useEffect(() => {
    fetchStats();
    fetchUsers(); // This will fetch all users initially
  }, []);
  
  // Handle filter changes
  useEffect(() => {
    setPage(0);
    setUsers([]);
    setHasMore(true);
    fetchUsers();
  }, [filters, searchEmail, searchName]); // Add search terms to dependencies
  
  // Handle pagination
  useEffect(() => {
    if (page > 0) {
      fetchUsers();
    }
  }, [page]);

  // API Methods
  const fetchStats = async () => {
    try {
      const statsData = await AdminService.getUserStats();
      setStats(statsData);
    } catch (error) {
      handleError(error);
    }
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      
      // If we don't have all users yet or it's a reload, fetch them
      if (allUsers.length === 0) {
        const response = await AdminService.getAllUsers();
        setAllUsers(response.content);
        
        // If no filters are applied, show all data
        if (!searchEmail && !searchName && filters.dateSort === 'none' && 
            filters.subscriptionFilter === 'all' && filters.nameSort === 'none') {
          setUsers(response.content);
          setTotalElements(response.content.length);
          setHasMore(false);
          setLoading(false);
          return;
        }
      }
      
      // Apply filters to allUsers
      let filteredResults = [...allUsers];
      
      // Only apply filters if any filter is active
      const hasActiveFilters = searchEmail || searchName || 
        filters.dateSort !== 'none' || 
        filters.subscriptionFilter !== 'all' || 
        filters.nameSort !== 'none';
  
      if (hasActiveFilters) {
        // Apply search filters
        if (searchEmail || searchName) {
          filteredResults = filteredResults.filter(user => {
            const matchesEmail = searchEmail ? 
              user.email.toLowerCase().includes(searchEmail.toLowerCase()) : true;
            const matchesName = searchName ? 
              user.name.toLowerCase().includes(searchName.toLowerCase()) : true;
            return matchesEmail && matchesName;
          });
        }
  
        // Apply subscription filter
        if (filters.subscriptionFilter !== 'all') {
          filteredResults = filteredResults.filter(user => {
            switch (filters.subscriptionFilter) {
              case 'subscribed':
                return user.subscribed;
              case 'free':
                return user.free;
              case 'inactive':
                return !user.subscribed && !user.free;
              default:
                return true;
            }
          });
        }
  
        // Apply sorting
        if (filters.dateSort !== 'none') {
          filteredResults.sort((a, b) => {
            const dateA = new Date(a.creationDate);
            const dateB = new Date(b.creationDate);
            return filters.dateSort === 'newest' 
              ? dateB - dateA 
              : dateA - dateB;
          });
        }
  
        if (filters.nameSort !== 'none') {
          filteredResults.sort((a, b) => {
            if (filters.nameSort === 'asc') {
              return a.name.localeCompare(b.name);
            } else {
              return b.name.localeCompare(a.name);
            }
          });
        }
      }
  
      // Handle pagination
      const startIndex = page * rowsPerPage;
      const paginatedResults = filteredResults.slice(startIndex, startIndex + rowsPerPage);
      
      setUsers(prevUsers => {
        if (page === 0) return paginatedResults;
        return [...prevUsers, ...paginatedResults];
      });
      
      setTotalElements(filteredResults.length);
      setHasMore(startIndex + rowsPerPage < filteredResults.length);
      
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  // Event Handlers
  const handleFilterChange = (filterType, value) => {
    setFilters(prev => {
      const newFilters = {
        ...prev,
        [filterType]: value
      };
      
      // Reset page when filters change
      setPage(0);
      setUsers([]);
      
      return newFilters;
    });
  };

  const handleSearch = () => {
    setPage(0);
    setUsers([]);
    setHasMore(true);
    fetchUsers();
  };

  const handleResetFilters = () => {
    setFilters({
      dateSort: 'none',
      subscriptionFilter: 'all',
      nameSort: 'none'
    });
    setSearchEmail('');
    setSearchName('');
    setPage(0);
    setUsers([]);
    setHasMore(true);
  };

  const handleError = (error) => {
    setSnackbar({
      open: true,
      message: error.message || 'An error occurred',
      severity: 'error'
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  const handleMakeAdmin = async (userId) => {
    try {
      await AdminService.makeAdmin(userId);
      setSnackbar({
        open: true,
        message: 'Successfully granted admin privileges',
        severity: 'success'
      });
      fetchUsers();
    } catch (error) {
      handleError(error);
    }
  };

  const handleRemoveAdmin = async (userId) => {
    try {
      await AdminService.removeAdmin(userId);
      setSnackbar({
        open: true,
        message: 'Successfully removed admin privileges',
        severity: 'success'
      });
      fetchUsers();
    } catch (error) {
      handleError(error);
    }
  };

  // Utility Functions
  const formatDate = (dateString) => {
    if (!dateString) return '-';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  // Statistics Cards Data
  const statCards = [
    { title: 'Total Users', value: stats.totalUsers, icon: 'mdi:account-multiple' },
    { title: 'Free Users', value: stats.freeUsers, icon: 'mdi:account-check' },
    { title: 'Verified Users', value: stats.verifiedUsers, icon: 'mdi:check-decagram' },
    { title: 'Subscribed Users', value: stats.subscribedUsers, icon: 'mdi:star-circle' }
  ];

  return (
    <StyledContainer maxWidth="xl">
      <StyledHeader>
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          Admin Dashboard
        </Typography>
        {loading && <CircularProgress size={24} sx={{ color: '#7695FF' }} />}
      </StyledHeader>

      {/* Stats Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        {statCards.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <StyledCard>
              <CardContent>
                <Box display="flex" alignItems="center" mb={1}>
                  <Icon 
                    icon={card.icon}
                    width={24}
                    height={24}
                    style={{ marginRight: 8, color: '#7695FF' }}
                  />
                  <Typography color="textSecondary">{card.title}</Typography>
                </Box>
                <Typography variant="h4" sx={{ fontWeight: 600 }}>
                  {card.value}
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>

      {/* Main Content Card */}
      <StyledCard>
        <CardContent>
          {/* Search Box */}
          <StyledSearchBox>
            <StyledTextField
              fullWidth
              label="Search by Email"
              value={searchEmail}
              onChange={(e) => setSearchEmail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <Icon icon="mdi:email" width={20} height={20} style={{ marginRight: 8, color: '#637381' }} />
                ),
              }}
            />
            <StyledTextField
              fullWidth
              label="Search by Name"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              InputProps={{
                startAdornment: (
                  <Icon icon="mdi:account" width={20} height={20} style={{ marginRight: 8, color: '#637381' }} />
                ),
              }}
            />
            <StyledButton
              onClick={handleSearch}
              disabled={loading}
            >
              <Icon icon="mdi:magnify" width={50} height={30} />
            </StyledButton>
          </StyledSearchBox>

          {/* Filter Controls */}
          <StyledFilterBox>
            <StyledFormControl size="small">
              <InputLabel>Date Sort</InputLabel>
              <Select
                value={filters.dateSort}
                label="Date Sort"
                onChange={(e) => handleFilterChange('dateSort', e.target.value)}
              >
                <MenuItem value="none">No Sort</MenuItem>
                <MenuItem value="newest">Newest First</MenuItem>
                <MenuItem value="oldest">Oldest First</MenuItem>
              </Select>
            </StyledFormControl>

            <StyledFormControl size="small">
              <InputLabel>Subscription</InputLabel>
              <Select
                value={filters.subscriptionFilter}
                label="Subscription"
                onChange={(e) => handleFilterChange('subscriptionFilter', e.target.value)}
              >
                <MenuItem value="all">All Users</MenuItem>
                <MenuItem value="subscribed">Subscribed</MenuItem>
                <MenuItem value="free">Free</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </Select>
            </StyledFormControl>

            <StyledFormControl size="small">
              <InputLabel>Name Sort</InputLabel>
              <Select
                value={filters.nameSort}
                label="Name Sort"
                onChange={(e) => handleFilterChange('nameSort', e.target.value)}
              >
                <MenuItem value="none">No Sort</MenuItem>
                <MenuItem value="asc">A to Z</MenuItem>
                <MenuItem value="desc">Z to A</MenuItem>
              </Select>
            </StyledFormControl>

            {(filters.dateSort !== 'none' || 
              filters.subscriptionFilter !== 'all' || 
              filters.nameSort !== 'none') && (
              <StyledButton
                variant="outlined"
                onClick={handleResetFilters}
                startIcon={<Icon icon="mdi:filter-off" />}
              >
                Clear Filters
              </StyledButton>
            )}
          </StyledFilterBox>

          <Divider sx={{ my: 2 }} />

          {/* Results Count */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" color="text.secondary">
              Showing {users.length} of {totalElements} users
            </Typography>
          </Box>

          {/* Users Table */}
          <TableContainer sx={{ maxHeight: 'calc(100vh - 350px)', overflow: 'auto' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Created Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Subscription</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user, index) => (
                  <TableRow 
                    key={user.id}
                    ref={index === users.length - 1 ? lastUserElementRef : null}
                  >
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{formatDate(user.creationDate)}</TableCell>
                    <TableCell>
                      <Box display="flex" gap={1} flexWrap="wrap">
                        <Chip
                          icon={<Icon icon={user.verified ? "mdi:check-circle" : "mdi:alert-circle"} />}
                          label={user.verified ? 'Verified' : 'Unverified'}
                          color={user.verified ? "success" : "warning"}
                          size="small"
                        />
                        {user.deleted && (
                          <Chip
                            icon={<Icon icon="mdi:trash-can" />}
                            label="Deleted"
                            color="error"
                            size="small"
                          />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Chip
                        icon={<Icon icon={user.subscribed ? "mdi:star" : "mdi:star-outline"} />}
                        label={user.free ? 'Free' : (user.subscribed ? 'Subscribed' : 'Inactive')}
                        color={user.subscribed ? "primary" : (user.free ? "default" : "error")}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton 
                        size="small" 
                        onClick={() => user.admin ? handleRemoveAdmin(user.id) : handleMakeAdmin(user.id)}
                        title={user.admin ? "Remove admin privileges" : "Grant admin privileges"}
                      >
                        <Icon 
                          icon={user.admin ? "mdi:shield-off" : "mdi:shield"} 
                          width={20} 
                          height={20} 
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            
            {loading && (
              <LoadingIndicator>
                <CircularProgress size={24} sx={{ color: '#7695FF' }} />
              </LoadingIndicator>
            )}
          </TableContainer>
        </CardContent>
      </StyledCard>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </StyledContainer>
  );
};

export default AdminDashboard;














