// import { Navigate } from 'react-router-dom';
// import trackerApi from './trackerApi';

// class Auth {
//   // Authentication  Class //
//   constructor() {
//     this.user_token = JSON.parse(localStorage.getItem('Auth')) || {};
//     this.isCd = false;
//     this.sent = false;
//   }

//   setSent(value) {
//     this.sent = value;
//     return this.sent; // Return the updated value of sent
//   }

//   getSent() {
//     return this.sent;
//   }

//   setIsCd(value) {
//     this.isCd = value;
//     return this.isCd; // Return the updated value of isCd
//   }

//   getIsCd() {
//     return this.user_token.isCd;
//   }

//   isSubscribed() {
//     return this.user_token.userDetails?.subscribed;
//   }

//   // Get Token //
//   getToken() {
//     return this.user_token.token;
//   }

//   // Get UserName //
//   getUserName() {
//     return this.user_token.userName;
//   }

//   // Get Login Id //
//   getUserLoginId() {
//     return this.user_token.id;
//   }

//   getVerified() {
//     return this.user_token.verified;
//   }

//   getCustomerId() {
//     return this.user_token.customerId;
//   }

//   // Get User's name //
//   getName() {
//     return this.user_token.name;
//   }

//   getIsFree() {
//     return this.user_token.free;
//   }

//   // Get User Email //
//   getEmail() {
//     return this.user_token.email;
//   }

//   // For setting LocalStorage //
//   setUserToken(newToken) {
//     this.user_token = newToken;
//     localStorage.setItem('Auth', JSON.stringify(newToken));
//   }

//   // For Removing localStorage //
//   removeLoginDetails() {
//     this.user_token.token = null;
//     localStorage.removeItem('Auth');
//     localStorage.removeItem('domainName');
//     localStorage.removeItem('r_k');
//   }

//   // User For Login //
//   authenticate = (loginData, callBack) => {
//     this.removeLoginDetails();
//     return trackerApi({
//       method: 'POST',
//       url: '/authenticate',
//       data: loginData,
//     })
//       .then((response) => {
//         this.setUserToken(response.data);
//         callBack({ status: 'success' });
//       })
//       .catch((error) => {
//         callBack({ status: 'error' });
//         console.log('Authentication failed', error);
//       });
//   };

//   // For Login with Google-Gmail //
//   authenticateGmail = (loginData, callBack) => {
//     this.removeLoginDetails();
//     return trackerApi({
//       method: 'POST',
//       url: '/authenticateGmail',
//       data: loginData,
//     })
//       .then((response) => {
//         this.setUserToken(response.data);
//         callBack({ status: 'success', data: response.data });
//       })
//       .catch((error) => {
//         console.log('Authentication by Gmail failed', error);
//       });
//   };

//   registerUser = (userData, callBack) => {
//     return trackerApi({
//       method: 'POST',
//       url: '/register',
//       data: userData,
//     })
//       .then((response) => {
//         callBack({ status: 'success', data: response.data });
//       })
//       .catch((error) => {
//         console.log('Authentication failed', error);
//         callBack({ status: 'error' });
//       });
//   };

//   verifyOTP = (otp, callBack) => {
//     return trackerApi({
//       method: 'POST',
//       url: '/user/public/get-by-otp',
//       data: otp,
//     })
//       .then((response) => {
//         callBack({ status: 'success', data: response.data });
//       })
//       .catch((error) => {
//         console.log('Authentication failed', error);
//         callBack({ status: 'error' });
//       });
//   };

//   forgotPassword = (email, callBack) => {
//     return trackerApi({
//       method: 'POST',
//       url: '/user/public/forgotPassword',
//       data: { email },
//     })
//       .then((response) => {
//         callBack({ status: 'success', data: response.data });
//       })
//       .catch((error) => {
//         console.log('Authentication failed', error);
//         callBack({ status: 'error' });
//       });
//   };

//   resetPassword = (passwordData, callBack) => {
//     return trackerApi({
//       method: 'POST',
//       url: '/user/public/resetPassword',
//       data: passwordData,
//     })
//       .then((response) => {
//         callBack({ status: 'success', data: response.data });
//       })
//       .catch((error) => {
//         console.log('Failed to set the new password:', error);
//         callBack({ status: 'error' });
//       });
//   };

//   // For Logout //
//   logout(callBack) {
//     this.removeLoginDetails();
//     callBack({ status: 'success' });
//   }

//   // For Checking User is Authenticated or not //
//   isAuthenticated() {
//     if (this.user_token !== undefined && this.user_token.token !== null && this.user_token.token !== undefined) {
//       return true;
//     }
//     return false;
//   }

//   // Subscribe or not //
//   // Subscribe or not //
//   async subscribed() {
//     try {
//       const id = this.getUserLoginId(); // Get the user ID from the login credentials
//       if (!id) {
//         return false; // Return false if the user ID is not available
//       }
//       const response = await trackerApi({
//         method: 'GET',
//         url: `/subs-check/${id}`,
//         headers: {
//           Authorization: `Bearer ${this.getToken()}`,
//         },
//       });
//       return response.data;
//     } catch (error) {
//       console.log('Error checking subscription:', error);
//       return false;
//     }
//   }





//   // setSubscribed = async () => {
//   //   const isSubscribed = await this.subscribed(this.getUserLoginId());
//   //   this.setState({isSubscribed});
//   // };

//   // setState(newState) {
//   //   this.state = { ...this.state, ...newState };
//   // }
// }

// export default new Auth();






import { Navigate } from 'react-router-dom';
import trackerApi from './trackerApi';

class Auth {
  constructor() {
    this.user_token = JSON.parse(localStorage.getItem('Auth')) || {};
    this.isCd = false;
    this.sent = false;
  }

  // Existing getters and setters
  setSent(value) {
    this.sent = value;
    return this.sent;
  }

  getSent() {
    return this.sent;
  }

  setIsCd(value) {
    this.isCd = value;
    return this.isCd;
  }

  // Updated user information getters
  getIsCd() {
    return this.user_token.isCd;
  }

  isSubscribed() {
    return this.user_token.userDetails?.subscribed;
  }

  getToken() {
    return this.user_token.token;
  }

  getUserName() {
    return this.user_token.userName;
  }

  getUserLoginId() {
    return this.user_token.id;
  }

  getVerified() {
    return this.user_token.verified;
  }

  getCustomerId() {
    return this.user_token.customerId;
  }

  getName() {
    return this.user_token.name;
  }

  getIsFree() {
    return this.user_token.free;
  }

  getEmail() {
    return this.user_token.email;
  }

  // Admin-specific methods
  isAdmin() {
    return this.user_token.userDetails?.isAdmin || false;
  }

  hasPermission(permission) {
    return this.user_token.userDetails?.permissions?.includes(permission) || false;
  }

  // User role and access methods
  getUserRole() {
    return this.user_token.userDetails?.role || 'user';
  }

  canAccessRoute(routePermission) {
    if (this.isAdmin()) return true;
    return this.hasPermission(routePermission);
  }

  // Updated token management
  setUserToken(newToken) {
    this.user_token = newToken;
    localStorage.setItem('Auth', JSON.stringify(newToken));
    // Optionally store last login timestamp
    localStorage.setItem('lastLogin', new Date().toISOString());
  }

  updateUserDetails(updates) {
    const currentToken = this.user_token;
    const updatedToken = {
      ...currentToken,
      userDetails: {
        ...currentToken.userDetails,
        ...updates
      }
    };
    this.setUserToken(updatedToken);
  }

  removeLoginDetails() {
    this.user_token = {};
    localStorage.removeItem('Auth');
    localStorage.removeItem('domainName');
    localStorage.removeItem('r_k');
    localStorage.removeItem('lastLogin');
  }

  // Authentication methods with error handling
  authenticate = async (loginData, callBack) => {
    try {
      this.removeLoginDetails();
      const response = await trackerApi({
        method: 'POST',
        url: '/authenticate',
        data: loginData,
      });
      
      this.setUserToken(response.data);
      callBack?.({ status: 'success', data: response.data });
      return response.data;
    } catch (error) {
      console.error('Authentication failed:', error);
      callBack?.({ 
        status: 'error', 
        message: error.response?.data?.message || 'Authentication failed' 
      });
      throw error;
    }
  };

  authenticateGmail = async (loginData, callBack) => {
    try {
      this.removeLoginDetails();
      const response = await trackerApi({
        method: 'POST',
        url: '/authenticateGmail',
        data: loginData,
      });
      
      this.setUserToken(response.data);
      callBack?.({ status: 'success', data: response.data });
      return response.data;
    } catch (error) {
      console.error('Gmail authentication failed:', error);
      callBack?.({ 
        status: 'error', 
        message: error.response?.data?.message || 'Gmail authentication failed' 
      });
      throw error;
    }
  };

  // User registration and management
  registerUser = async (userData, callBack) => {
    try {
      const response = await trackerApi({
        method: 'POST',
        url: '/register',
        data: userData,
      });
      
      callBack?.({ status: 'success', data: response.data });
      return response.data;
    } catch (error) {
      console.error('Registration failed:', error);
      callBack?.({ 
        status: 'error', 
        message: error.response?.data?.message || 'Registration failed' 
      });
      throw error;
    }
  };

  verifyOTP = async (otp, callBack) => {
    try {
      const response = await trackerApi({
        method: 'POST',
        url: '/user/public/get-by-otp',
        data: otp,
      });
      
      callBack?.({ status: 'success', data: response.data });
      return response.data;
    } catch (error) {
      console.error('OTP verification failed:', error);
      callBack?.({ 
        status: 'error', 
        message: error.response?.data?.message || 'OTP verification failed' 
      });
      throw error;
    }
  };

  // Password management
  forgotPassword = async (email, callBack) => {
    try {
      const response = await trackerApi({
        method: 'POST',
        url: '/user/public/forgotPassword',
        data: { email },
      });
      
      callBack?.({ status: 'success', data: response.data });
      return response.data;
    } catch (error) {
      console.error('Password reset request failed:', error);
      callBack?.({ 
        status: 'error', 
        message: error.response?.data?.message || 'Password reset request failed' 
      });
      throw error;
    }
  };

  resetPassword = async (passwordData, callBack) => {
    try {
      const response = await trackerApi({
        method: 'POST',
        url: '/user/public/resetPassword',
        data: passwordData,
      });
      
      callBack?.({ status: 'success', data: response.data });
      return response.data;
    } catch (error) {
      console.error('Password reset failed:', error);
      callBack?.({ 
        status: 'error', 
        message: error.response?.data?.message || 'Password reset failed' 
      });
      throw error;
    }
  };

  // Session management
  logout = async (callBack) => {
    try {
      // Optionally call logout endpoint if needed
      // await trackerApi.post('/logout');
      this.removeLoginDetails();
      callBack?.({ status: 'success' });
    } catch (error) {
      console.error('Logout failed:', error);
      callBack?.({ 
        status: 'error', 
        message: error.response?.data?.message || 'Logout failed' 
      });
      throw error;
    }
  };

  // Authentication status
  isAuthenticated() {
    const token = this.getToken();
    if (!token) return false;

    // Check token expiration if you have exp claim
    const tokenData = this.user_token.tokenData;
    if (tokenData?.exp) {
      const currentTime = Math.floor(Date.now() / 1000);
      return tokenData.exp > currentTime;
    }

    return true;
  }

  // Subscription status
  async subscribed() {
    try {
      const id = this.getUserLoginId();
      if (!id) return false;

      const response = await trackerApi({
        method: 'GET',
        url: `/subs-check/${id}`,
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      });

      // Update subscription status in user details
      if (response.data !== this.user_token.userDetails?.subscribed) {
        this.updateUserDetails({ subscribed: response.data });
      }

      return response.data;
    } catch (error) {
      console.error('Subscription check failed:', error);
      return false;
    }
  }

  // Session validity check
  async validateSession() {
    try {
      const response = await trackerApi({
        method: 'GET',
        url: '/validate-session',
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      });
      return response.data.valid;
    } catch (error) {
      console.error('Session validation failed:', error);
      return false;
    }
  }
}

export default new Auth();
