
// import React, { useState } from 'react';
// import { styled } from '@mui/material/styles';
// import {
//   Box,
//   Drawer,
//   AppBar,
//   Toolbar,
//   List,
//   Typography,
//   Divider,
//   IconButton,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   CssBaseline,
//   useTheme,
//   Avatar,
//   CircularProgress
// } from '@mui/material';
// import { Icon } from '@iconify/react';
// import { useNavigate, useLocation, Navigate } from 'react-router-dom';
// import AdminService from 'src/module/adminService';
// import AdminDashboard from '../components/admin/AdminDashboard';
// import Auth from '../module/Auth';

// const drawerWidth = 240;

// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
//   ({ theme, open }) => ({
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: `-${drawerWidth}px`,
//     ...(open && {
//       transition: theme.transitions.create('margin', {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginLeft: 0,
//     }),
//   }),
// );

// const AppBarStyled = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open' })(
//   ({ theme, open }) => ({
//     transition: theme.transitions.create(['margin', 'width'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     ...(open && {
//       width: `calc(100% - ${drawerWidth}px)`,
//       marginLeft: `${drawerWidth}px`,
//       transition: theme.transitions.create(['margin', 'width'], {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//     }),
//     backgroundColor: '#fff',
//     boxShadow: '0 8px 40px rgba(118, 149, 255, 0.1)',
//   }),
// );

// const DrawerHeader = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(0, 1),
//   ...theme.mixins.toolbar,
//   justifyContent: 'space-between',
// }));

// const StyledListItem = styled(ListItem)(({ theme }) => ({
//   margin: theme.spacing(0.5, 1),
//   borderRadius: theme.spacing(1),
//   '&.Mui-selected': {
//     backgroundColor: 'rgba(118, 149, 255, 0.1)',
//     '&:hover': {
//       backgroundColor: 'rgba(118, 149, 255, 0.2)',
//     },
//     '& .MuiListItemIcon-root': {
//       color: '#7695FF',
//     },
//     '& .MuiListItemText-primary': {
//       color: '#7695FF',
//       fontWeight: 600,
//     },
//   },
// }));

// const menuItems = [
//   { text: 'Dashboard', icon: 'material-symbols:dashboard', path: '/admin/dashboard' },
//   { text: 'Users', icon: 'mdi:account-group', path: '/admin/users' },
//   { text: 'Settings', icon: 'mdi:cog', path: '/admin/settings' },
// ];



// const AdminPage = () => {
//     const theme = useTheme();
//     const navigate = useNavigate();
//     const location = useLocation();
//     const [open, setOpen] = useState(true);
//     const [isLoading, setIsLoading] = useState(true);
//     const [isAdmin, setIsAdmin] = useState(null);

//     React.useEffect(() => {
//         const checkAdminStatus = async () => {
//             try {
//                 const userId = Auth.getUserLoginId();
                
//                 if (!userId) {
//                     setIsAdmin(false);
//                     return;
//                 }
                
//                 const adminResponse = await AdminService.getAdmin(userId);
//                 setIsAdmin(adminResponse);
//             } catch (error) {
//                 console.error('Error checking admin status:', error);
//                 setIsAdmin(false);
//             } finally {
//                 setIsLoading(false);
//             }
//         };
        
//         checkAdminStatus();
//     }, []);

//     const handleDrawerOpen = () => {
//         setOpen(true);
//     };

//     const handleDrawerClose = () => {
//         setOpen(false);
//     };

//     const handleLogout = () => {
//         Auth.logout(() => {
//             navigate('/login');
//         });
//     };

//     if (isLoading) {
//         return (
//             <Box 
//                 sx={{ 
//                     display: 'flex', 
//                     justifyContent: 'center', 
//                     alignItems: 'center', 
//                     minHeight: '100vh' 
//                 }}
//             >
//                 <CircularProgress />
//             </Box>
//         );
//     }

//     if (!isAdmin) {
//         return <Navigate to="/404" replace />;
//     }

//     return (
//         <Box sx={{ display: 'flex', minHeight: '90%', bgcolor: '#F8F9FF' }}>
//             <CssBaseline />
//             <AppBarStyled position="fixed" open={open}>
//                 <Toolbar>
//                     <IconButton
//                         color="primary"
//                         aria-label="open drawer"
//                         onClick={handleDrawerOpen}
//                         edge="start"
//                         sx={{ mr: 2, ...(open && { display: 'none' }) }}
//                     >
//                         <Icon icon="mdi:menu" width={24} height={24} />
//                     </IconButton>
//                     <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
//                         <Typography variant="h6" noWrap component="div" sx={{ color: '#1a1a1a' }}>
//                             {location.pathname.split('/').pop().charAt(0).toUpperCase() + 
//                             location.pathname.split('/').pop().slice(1)}
//                         </Typography>
//                     </Box>
//                     <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
//                         <IconButton
//                             color="primary"
//                             size="small"
//                             sx={{ bgcolor: 'rgba(118, 149, 255, 0.1)' }}
//                         >
//                             <Icon icon="mdi:bell-outline" width={20} height={20} />
//                         </IconButton>
//                         <IconButton 
//                             onClick={handleLogout}
//                             color="primary"
//                             size="small"
//                             sx={{ bgcolor: 'rgba(118, 149, 255, 0.1)' }}
//                         >
//                             <Icon icon="mdi:logout" width={20} height={20} />
//                         </IconButton>
//                         <Avatar 
//                             alt={Auth.getName()} 
//                             src="/static/mock-images/avatars/avatar_default.jpg"
//                             sx={{ width: 40, height: 40 }}
//                         />
//                     </Box>
//                 </Toolbar>
//             </AppBarStyled>
            
//             <Drawer
//                 sx={{
//                     width: drawerWidth,
//                     flexShrink: 0,
//                     '& .MuiDrawer-paper': {
//                         width: drawerWidth,
//                         boxSizing: 'border-box',
//                         border: 'none',
//                         backgroundColor: '#fff',
//                         boxShadow: '0 8px 40px rgba(118, 149, 255, 0.1)',
//                     },
//                 }}
//                 variant="persistent"
//                 anchor="left"
//                 open={open}
//             >
//                 <DrawerHeader>
//                     <Box sx={{ display: 'flex', alignItems: 'center', pl: 2 }}>
//                         <Icon 
//                             icon="mdi:shield-crown" 
//                             width={32} 
//                             height={32} 
//                             style={{ color: '#7695FF', marginRight: '12px' }} 
//                         />
//                         <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#1a1a1a' }}>
//                             Admin Panel
//                         </Typography>
//                     </Box>
//                     <IconButton onClick={handleDrawerClose}>
//                         <Icon icon="mdi:chevron-left" width={24} height={24} />
//                     </IconButton>
//                 </DrawerHeader>
//                 <Divider sx={{ borderColor: 'rgba(118, 149, 255, 0.1)' }} />
//                 <List sx={{ px: 1, py: 2 }}>
//                     {menuItems.map((item) => (
//                         <StyledListItem
//                             button
//                             key={item.text}
//                             onClick={() => navigate(item.path)}
//                             selected={location.pathname === item.path}
//                         >
//                             <ListItemIcon>
//                                 <Icon 
//                                     icon={item.icon} 
//                                     width={24} 
//                                     height={24} 
//                                     style={{ 
//                                         color: location.pathname === item.path ? '#7695FF' : '#637381'
//                                     }}
//                                 />
//                             </ListItemIcon>
//                             <ListItemText 
//                                 primary={item.text} 
//                                 primaryTypographyProps={{
//                                     style: {
//                                         fontWeight: location.pathname === item.path ? 600 : 400,
//                                         color: location.pathname === item.path ? '#7695FF' : '#637381'
//                                     }
//                                 }}
//                             />
//                         </StyledListItem>
//                     ))}
//                 </List>
                
//                 <Box sx={{ flexGrow: 1 }} />
                
//                 <Box sx={{ p: 2, borderTop: '1px solid rgba(118, 149, 255, 0.1)' }}>
//                     <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
//                         <Avatar 
//                             alt={Auth.getName()} 
//                             src="/static/mock-images/avatars/avatar_default.jpg"
//                             sx={{ width: 40, height: 40 }}
//                         />
//                         <Box>
//                             <Typography variant="subtitle2" sx={{ color: '#1a1a1a', fontWeight: 600 }}>
//                                 {Auth.getName()}
//                             </Typography>
//                             <Typography variant="caption" sx={{ color: '#637381' }}>
//                                 {Auth.getEmail()}
//                             </Typography>
//                         </Box>
//                     </Box>
//                 </Box>
//             </Drawer>
            
//             <Main open={open}>
//                 <DrawerHeader />
                
//             </Main>
//             <AdminDashboard />
//         </Box>
//     );
// };

// export default AdminPage;




import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  useTheme,
  Avatar,
  CircularProgress
} from '@mui/material';
import { Icon } from '@iconify/react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import AdminService from 'src/module/adminService';
import ActivityMonitoring from 'src/components/user-activity/UserActivityMonitoring';
import AdminDashboard from '../components/admin/AdminDashboard';
import Auth from '../module/Auth';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBarStyled = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    backgroundColor: '#fff',
    boxShadow: '0 8px 40px rgba(118, 149, 255, 0.1)',
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  margin: theme.spacing(0.5, 1),
  borderRadius: theme.spacing(1),
  '&.Mui-selected': {
    backgroundColor: 'rgba(118, 149, 255, 0.1)',
    '&:hover': {
      backgroundColor: 'rgba(118, 149, 255, 0.2)',
    },
    '& .MuiListItemIcon-root': {
      color: '#7695FF',
    },
    '& .MuiListItemText-primary': {
      color: '#7695FF',
      fontWeight: 600,
    },
  },
}));

// Updated menu items to include only Dashboard and Activity Monitoring
const menuItems = [
  { text: 'Dashboard', icon: 'material-symbols:dashboard', path: '/admin/dashboard' },
  { text: 'Activity Monitoring', icon: 'mdi:monitor', path: '/admin/activity' },
];

const AdminPage = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(null);
    const [currentPage, setCurrentPage] = useState('dashboard');

    React.useEffect(() => {
        const checkAdminStatus = async () => {
            try {
                const userId = Auth.getUserLoginId();
                
                if (!userId) {
                    setIsAdmin(false);
                    return;
                }
                
                const adminResponse = await AdminService.getAdmin(userId);
                setIsAdmin(adminResponse);
            } catch (error) {
                console.error('Error checking admin status:', error);
                setIsAdmin(false);
            } finally {
                setIsLoading(false);
            }
        };
        
        checkAdminStatus();
    }, []);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMenuClick = (path) => {
        const page = path.split('/').pop();
        setCurrentPage(page);
    };

    const handleLogout = () => {
        Auth.logout(() => {
            navigate('/login');
        });
    };

    if (isLoading) {
        return (
            <Box 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    minHeight: '100vh' 
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    if (!isAdmin) {
        return <Navigate to="/404" replace />;
    }

    return (
        <Box sx={{ display: 'flex', minHeight: '90%', bgcolor: '#F8F9FF' }}>
            <CssBaseline />
            <AppBarStyled position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="primary"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <Icon icon="mdi:menu" width={24} height={24} />
                    </IconButton>
                    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6" noWrap component="div" sx={{ color: '#1a1a1a' }}>
                            {currentPage.charAt(0).toUpperCase() + currentPage.slice(1)}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <IconButton
                            color="primary"
                            size="small"
                            sx={{ bgcolor: 'rgba(118, 149, 255, 0.1)' }}
                        >
                            <Icon icon="mdi:bell-outline" width={20} height={20} />
                        </IconButton>
                        <IconButton 
                            onClick={handleLogout}
                            color="primary"
                            size="small"
                            sx={{ bgcolor: 'rgba(118, 149, 255, 0.1)' }}
                        >
                            <Icon icon="mdi:logout" width={20} height={20} />
                        </IconButton>
                        <Avatar 
                            alt={Auth.getName()} 
                            src="/static/mock-images/avatars/avatar_default.jpg"
                            sx={{ width: 40, height: 40 }}
                        />
                    </Box>
                </Toolbar>
            </AppBarStyled>
            
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        border: 'none',
                        backgroundColor: '#fff',
                        boxShadow: '0 8px 40px rgba(118, 149, 255, 0.1)',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 2 }}>
                        <Icon 
                            icon="mdi:shield-crown" 
                            width={32} 
                            height={32} 
                            style={{ color: '#7695FF', marginRight: '12px' }} 
                        />
                        <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#1a1a1a' }}>
                            Admin Panel
                        </Typography>
                    </Box>
                    <IconButton onClick={handleDrawerClose}>
                        <Icon icon="mdi:chevron-left" width={24} height={24} />
                    </IconButton>
                </DrawerHeader>
                <Divider sx={{ borderColor: 'rgba(118, 149, 255, 0.1)' }} />
                <List sx={{ px: 1, py: 2 }}>
                    {menuItems.map((item) => (
                        <StyledListItem
                            button
                            key={item.text}
                            onClick={() => handleMenuClick(item.path)}
                            selected={currentPage === item.path.split('/').pop()}
                        >
                            <ListItemIcon>
                                <Icon 
                                    icon={item.icon} 
                                    width={24} 
                                    height={24} 
                                    style={{ 
                                        color: currentPage === item.path.split('/').pop() ? '#7695FF' : '#637381'
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText 
                                primary={item.text} 
                                primaryTypographyProps={{
                                    style: {
                                        fontWeight: currentPage === item.path.split('/').pop() ? 600 : 400,
                                        color: currentPage === item.path.split('/').pop() ? '#7695FF' : '#637381'
                                    }
                                }}
                            />
                        </StyledListItem>
                    ))}
                </List>
                
                <Box sx={{ flexGrow: 1 }} />
                
                <Box sx={{ p: 2, borderTop: '1px solid rgba(118, 149, 255, 0.1)' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Avatar 
                            alt={Auth.getName()} 
                            src="/static/mock-images/avatars/avatar_default.jpg"
                            sx={{ width: 40, height: 40 }}
                        />
                        <Box>
                            <Typography variant="subtitle2" sx={{ color: '#1a1a1a', fontWeight: 600 }}>
                                {Auth.getName()}
                            </Typography>
                            <Typography variant="caption" sx={{ color: '#637381' }}>
                                {Auth.getEmail()}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Drawer>
            
            <Main open={open}>
                <DrawerHeader />
                {currentPage === 'dashboard' && <AdminDashboard />}
                {currentPage === 'activity' && <ActivityMonitoring />}
            </Main>
        </Box>
    );
};

export default AdminPage;